
// import {WOW} from '../../../node_modules/wowjs/dist/wow';

// new WOW().init();


//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > 50) { //距离顶部大于140px时
    $('.root-header').addClass('scroll-header')
  } else {
    $('.root-header').removeClass('scroll-header')
  }
  if (contentH - (scroH + viewH) <= 100) { //距离底部高度小于100px

  }
  if (contentH = (scroH + viewH)) { //滚动条滑到底部啦

  }

});



$(document).ready(function(){
  $('.tab-title div').on('click',function(){
    if($(window).width() < 992){
        $('.tab-title-cont .mob-title').removeClass('active')
        $('.tab-title-cont .tab-title').slideUp(200)
    }
  })
  
  $('.tab-title-cont .mob-title').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active').siblings('.tab-title').slideUp(200)
    }else{
      $(this).addClass('active').siblings('.tab-title').slideDown(200)
    }
  })
  
  $('body').on('click',function(e){
    if($(window).width() < 992){
      if($(e.target).parents('.tab-title-cont').length == 0){
        $('.tab-title-cont .mob-title').removeClass('active').siblings('.tab-title').slideUp(200)
      }
    }
  })
  
  $('.common-ny-cont .right-menu ul li .title').on('click',function(){
    if($(window).width() >= 992){
      var $li = $(this).closest('li')
      if($li.hasClass('hover')){
        $li.removeClass('hover').find('.subnav').slideUp(200)
      }else{
        $li.siblings().removeClass('hover').find('.subnav').slideUp(200)
        $li.addClass('hover').find('.subnav').slideDown(200)
      }
    }
  })

  if($('.common-ny-cont .right-menu ul li.active').find('.subnav').length > 0 && $(window).width() >= 992){
    $('.common-ny-cont .right-menu ul li.active').addClass('hover').find('.subnav').slideDown(200)
  }
})